import { display, hide as hideOne } from 'lambda-dom'
import { values } from 'ramda'

import {
    desktopHeader,
    desktopHeaderLogo,
    desktopHeaderShareInfo,
    mobileHeader,
    mobileHeaderLogo,
    mobileHeaderShareInfo,
} from '@app-bootstrappers'
import { HeaderDisplay, HeaderTheme } from './site-header.types'

// ------------------------------------------------------------------------------
//      Private: Helpers
// ------------------------------------------------------------------------------

const show = (elements: HTMLElement[], displayValue?: string) => elements.forEach(display(displayValue || 'block'))
const hide = (elements: HTMLElement[]) => elements.forEach(hideOne)

// ------------------------------------------------------------------------------
//     Private:  State
// ------------------------------------------------------------------------------

let currentTheme: HeaderTheme
let currentDisplayMode: HeaderDisplay

// ------------------------------------------------------------------------------
//      Library
// ------------------------------------------------------------------------------

/**
 * Get the name of the currently active theme.
 */
export const getHeaderTheme = () => currentTheme

/**
 * Get the name of the currently active display mode.
 */
export const getDisplayMode = () => currentDisplayMode

/**
 * Set the color scheme for both desktop and mobile headers & navigation to the
 * given theme object; one of the set of predefined themes.
 */
export const setHeaderTheme = (theme: HeaderTheme): void => {

    const allThemes = values(HeaderTheme)

    desktopHeader?.classList?.remove(...allThemes)
    desktopHeader?.classList?.add(theme)
    mobileHeader?.classList?.remove(...allThemes)
    mobileHeader?.classList?.add(theme)

    currentTheme = theme
}

/**
 * Toggles the header-display mode, activating the mode by given name. Allows to
 * dynamically switch between the Company logo and slogan, the share-value update,
 * or neither.
 */
export const setHeaderDisplay = (newDisplay: HeaderDisplay): HeaderDisplay => {
    switch (newDisplay) {
        case 'brand-logo':
            if (mobileHeaderLogo) {
                show([mobileHeaderLogo])
            }
            if (desktopHeaderLogo) {
                show([desktopHeaderLogo], 'flex')
            }
            if (desktopHeaderShareInfo) {
                hide([desktopHeaderShareInfo])
            }
            if (mobileHeaderShareInfo) {
                hide([mobileHeaderShareInfo])
            }
            break

        case 'share-info':
            if (desktopHeaderLogo) {
                hide([desktopHeaderLogo])
            }
            if (mobileHeaderLogo) {
                hide([mobileHeaderLogo])
            }
            if (desktopHeaderShareInfo) {
                show([desktopHeaderShareInfo], 'flex')
            }
            if (mobileHeaderShareInfo) {
                show([mobileHeaderShareInfo], 'flex')
            }
            break

        case 'none':
            if (desktopHeaderLogo) {
                hide([desktopHeaderLogo])
            }
            if (desktopHeaderShareInfo) {
                hide([desktopHeaderShareInfo])
            }
            if (mobileHeaderLogo) {
                hide([mobileHeaderLogo])
            }
            if (mobileHeaderShareInfo) {
                hide([mobileHeaderShareInfo])
            }
            break

        default:
            return currentDisplayMode
    }

    currentDisplayMode = newDisplay
    return currentDisplayMode
}
